<template>
  <div class="round-tab-container">
    <div class="round-tab-header">
      <div
        class="round-tab-item"
        @click="setActiveItem(item)"
        v-for="item in tabs"
        :key="item.value"
        :data-test="`Round:Tabs:RoundStep:${item.value}`"
      >
        <span class="round-tab-label"> {{ $t(item.label) }} </span>
        <div
          :class="{ 'round-tab-active': activeTab === item.value }"
          class="round-tab-bar"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllocationsTableTabs",
  data: () => ({
    activeTab: 1,
    tabs: [
      {
        value: 1,
        label: "allocations_in_progress",
      },
      {
        value: 2,
        label: "finalized_allocations",
      },
    ],
  }),
  methods: {
    setActiveItem(item) {
      this.activeTab = item.value;
      this.$emit("changeAllocationTab", item);
    },
  },
};
</script>

<style scoped>
.round-tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.round-tab-header {
  display: flex;
  flex-direction: row;
}

.round-tab-item {
  cursor: pointer;
  padding: 20px 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.round-tab-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-tab-bar {
  background: #e9e9e9;
  height: 8px;
  margin-top: 8px;
  width: 100%;
}

.round-tab-active {
  background: var(--primary);
}

.round-tab-content {
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
}

@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
