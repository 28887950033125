<template>
  <div id="rounds-bg" style="min-height: 100vh">
    <div class="round-loading" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <div id="rounds-content" v-else>
      <div class="round-allocations-header">
        <a class="round-allocations-header-back-page" @click="$router.go(-1)">
          <font-awesome-icon
            icon="fa-solid fa-arrow-left"
            class="round-allocations-header-back-page-icon"
          />
          <span class="round-allocations-header-back-page-text">{{
            `
            ${$t("single_round")} #${roundData.roundId} 
          `
          }}</span>
        </a>
        <div class="round-allocations-header-flex">
          <div>
            <h1 class="round-allocations-header-title">
              <span>{{ `${$t("single_round")} #${roundData.roundId}` }}</span>
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
                class="icon-arrow-breadcrumb"
              />
              <span>{{ $t("allocations") }}</span>
            </h1>
            <p class="round-allocations-header-sub-title">
              {{ $t("round_allocations_subtitle") }}
            </p>
          </div>
          <div class="round-allocations-header-buttons">
            <div>
              <NewDxaButton
                :title="$t('create_new_allocation')"
                :disabled="buttonCancelRoundDisabled || isManagerUser"
                @btnFunction="cancelRoundDialog = true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="round-allocations-table">
        <AllocationsTableComponent
          :roundId="roundData.roundId"
          :filterByRound="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { roundStatusEnums } from "./RoundStatusEnums.js";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import AllocationsTableComponent from "@/views/pages/allocations/components/AllocationsTableComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "RoundAllocations",
  components: {
    NewDxaButton,
    AllocationsTableComponent,
  },
  data() {
    return {
      loading: false,
      statusCircleIconColor: null,
      selectOpportunityText: null,
      roundDetailsStatus: null,
      formatToCurrency: formatCurrency,
      roundData: null,
      apiService: new ApiService(),
    };
  },
  created() {
    this.getRoundAllocationsData();
  },
  computed: {
    ...mapGetters({ isManagerUser: "get_is_manager_user" }),
  },
  methods: {
    async getRoundAllocationsData() {
      this.loading = true;
      const roundId = this.$route.params.roundId;
      await this.apiService
        .getRequest(`investmentround/${roundId}/allocations`)
        .then((result) => {
          this.roundData = result.content;
          this.lastUpdate = format(
            new Date(result.content.lastUpdate),
            "dd/MM/yyyy",
            {
              locale: ptBR,
            }
          );
          this.showRoundStatusLabel();
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.loading = false;
    },
    showRoundStatusLabel() {
      const documentStyle = window.getComputedStyle(document.documentElement);
      const documentDarkColor = documentStyle.getPropertyValue("--dark");

      switch (this.roundData.roundStatus) {
        case roundStatusEnums.AWAITING_PUBLISH:
          this.statusCircleIconColor = "#DADADA";
          this.selectOpportunityText = "round_status_await_publish";
          this.roundDetailsStatus = this.$t("round_status_created");
          break;
        case roundStatusEnums.PUBLISHED:
          this.statusCircleIconColor = "#01D181";
          this.selectOpportunityText = "opportunity_open";
          this.roundDetailsStatus = this.$t("round_status_published");
          break;
        case roundStatusEnums.CLOSED:
          this.statusCircleIconColor = documentDarkColor;
          this.selectOpportunityText = "opportunity_closed";
          this.selectOpportunityTextColor = documentDarkColor;
          this.roundDetailsStatus = this.$t("round_status_closed");
          break;
        case roundStatusEnums.CANCELED:
          this.statusCircleIconColor = "#FF0000";
          this.selectOpportunityText = "opportunity_canceled";
          this.selectOpportunityTextColor = "#FF0000";
          this.roundDetailsStatus = this.$t("round_status_canceled");
          break;
        default:
          this.selectOpportunityTextColor = "#535353";
      }
    },
  },
};
</script>

<style scoped>
#rounds-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
}

#rounds-content {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 40px;
}
.round-allocations-content {
  padding: 0 60px;
}
.round-allocations-header {
  margin-bottom: 40px;
}

.round-allocations-header-back-page {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.round-allocations-header-back-page-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.round-allocations-header-back-page-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-allocations-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.round-allocations-header-sub-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-allocations-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-allocations-header-buttons {
  display: flex;
  align-items: center;
  gap: 60px;
}

.round-allocations-table {
  margin-top: 40px;
}

.icon-arrow-breadcrumb {
  width: 24px;
  height: 24px;
  color: #b3b3b3;
  margin-left: 16px;
  margin-right: 16px;
}

.round-loading {
  display: grid;
  place-items: center;
  margin-top: 120px;
}
</style>
