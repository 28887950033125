<template>
  <div class="interest-table-component-container">
    <AllocationsTableTabs
      @changeAllocationTab="(tab) => changeAllocationTab(tab)"
    />
    <div>
      <v-form
        @submit.stop.prevent="getAllocationsByPage"
        class="interest-table-component-filter-container"
      >
        <div>
          <span class="interests-table-component-filters-label">{{
            $t("search_label")
          }}</span>
          <v-text-field
            v-model="searchInput"
            color="primary"
            dense
            class="interests-table-component-filters-auto-complete"
            outlined
          >
          </v-text-field>
        </div>
        <div>
          <span class="interests-table-component-filters-label">{{
            $tc("company", 1)
          }}</span>
          <v-autocomplete
            :disabled="filterByRound"
            :items="companiesOptions"
            item-text="Name"
            item-value="CompanyId"
            v-model="selectedCompany"
            color="primary"
            dense
            class="interests-table-component-filters-auto-complete"
            :label="$tc('all_label', 1)"
            outlined
          >
          </v-autocomplete>
        </div>
        <div>
          <span class="interests-table-component-filters-label">{{
            $t("rounds_state_title")
          }}</span>
          <v-autocomplete
            :items="allocationStatusItems"
            :item-text="translateAllocationsStatus"
            item-value="value"
            v-model="selectedStatus"
            color="primary"
            dense
            class="interests-table-component-filters-auto-complete"
            :label="$tc('all_label', 2)"
            outlined
          >
          </v-autocomplete>
        </div>
        <div>
          <!-- datas -->
          <span class="interests-table-component-filters-label">{{
            $t("rounds_time_title")
          }}</span>
          <div class="allocation-date-flex-items">
            <v-menu
              ref="startMenuDateRef"
              v-model="allocationStartMenuDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  color="#AFAFAF"
                  background-color="white"
                  dense
                  class="rounds-filters-date"
                  outlined
                  style="width: 100%"
                  v-model="allocationStartDateText"
                  v-mask="['##/##/####']"
                  append-icon="mdi-calendar"
                  @blur="
                    allocationStartDate = formatDateOnBlur(
                      allocationStartDateText
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  label="DD/MM/AA"
                  height="34"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-BR"
                color="primary"
                v-model="allocationStartDate"
                min="1850-01-01"
                @change="saveAllocationStartDate"
              ></v-date-picker>
            </v-menu>
            <span class="allocation-date-item-space-letter">a</span>
            <v-menu
              ref="endMenuDateRef"
              v-model="allocationEndMenuDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  color="#AFAFAF"
                  background-color="white"
                  dense
                  class="rounds-filters-date"
                  outlined
                  style="width: 100%"
                  v-model="allocationFinishDateText"
                  v-mask="['##/##/####']"
                  append-icon="mdi-calendar"
                  @blur="
                    allocationFinishDate = formatDateOnBlur(
                      allocationFinishDateText
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  label="DD/MM/AA"
                  height="34"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-BR"
                color="primary"
                v-model="allocationFinishDate"
                min="1850-01-01"
                @change="saveAllocationEndDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <div class="interest-table-component-button-filter">
          <NewDxaButton
            type="submit"
            :outline="true"
            :title="$tc('filter_kpi', 1)"
            @btnFunction="getRoundByPage"
            :loading="loading"
          />
        </div>
      </v-form>
    </div>
    <div class="box-loading" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-simple-table v-else>
      <thead class="interest-table-thead">
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $tc("investor", 1) }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.INVESTOR
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.INVESTOR)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.INVESTOR
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $t("date") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.DATE
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.DATE)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.DATE
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $t("value") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.VALUE
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.VALUE)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.VALUE
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th v-if="!filterByRound" class="text-left">
          <div class="table-thead-flex">
            {{ $tc("company", 1) }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.COMPANY
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.COMPANY)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.COMPANY
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th v-if="!filterByRound" class="text-left">
          <div class="table-thead-flex">
            {{ $t("single_round") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.ROUND
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.ROUND)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.ROUND
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $t("vehicle_title_single") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.VEHICLE
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.VEHICLE)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.VEHICLE
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $t("status") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.STATUS
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.STATUS)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.STATUS
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th v-if="!isManagerUser"></th>
      </thead>
      <tbody class="interest-table-body">
        <tr v-for="(allocation, i) in allocations" :key="i">
          <td @click="goInvestorProfile(allocation.investorId)">
            <span
              id="interest-table-body-investor-name"
              :style="`${
                allocation.investorId
                  ? 'text-decoration: underline'
                  : 'text-decoration: none'
              }`"
            >
              {{ allocation.investorName }}
            </span>
          </td>
          <td>
            <span>{{ formatDate(allocation.startDate) }}</span>
          </td>
          <td>
            <span>{{
              formatCurrency(allocation.currency, allocation.value)
            }}</span>
          </td>
          <td v-if="!filterByRound">
            <span>{{ allocation.companyName }}</span>
          </td>
          <td v-if="!filterByRound">
            <span>{{ allocation.roundId }}</span>
          </td>
          <td>
            <span v-if="allocation.vehicleName">{{
              allocation.vehicleName
            }}</span>
            <span v-else>-</span>
          </td>
          <td>
            <span
              :style="`color: ${selectedAllocationStatusColor(
                allocation.status
              )}`"
              >{{ $t(selectedAllocationStatus(allocation.status)) }}</span
            >
          </td>
          <td
            v-if="!isManagerUser"
            @click="goToAllocationDetails(allocation.id)"
            id="interests-table-body-see-details"
          >
            <font-awesome-icon icon="fa-solid fa-eye" />
            <span> Detalhes </span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      v-if="!loading"
      class="mt-6"
      color="primary"
      @input="changePage"
      v-model="page"
      :length="pageCount"
    >
    </v-pagination>
  </div>
</template>

<script>
import AllocationsTableTabs from "./AllocationsTableTabs";
import ApiService from "@/services/ApiService";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import {
  allocationStatusEnums,
  allocationTabEnums,
  allocationTableEnum,
} from "../AllocationStatusEnums";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { formatDate } from "@/shared/helpers/dateHelper";
import { mapGetters } from "vuex";

const OrderFilterEnum = {
  INVESTOR: allocationTableEnum.INVESTOR,
  DATE: allocationTableEnum.DATE,
  VALUE: allocationTableEnum.VALUE,
  COMPANY: allocationTableEnum.COMPANY,
  ROUND: allocationTableEnum.ROUND,
  VEHICLE: allocationTableEnum.VEHICLE,
  STATUS: allocationTableEnum.STATUS,
};

const OrderByModeEnum = {
  ASC: 1,
  DESC: 2,
};

export default {
  name: "AllocationsTableComponent",
  components: {
    AllocationsTableTabs,
    NewDxaButton,
  },
  props: {
    roundId: Number,
    filterByRound: { type: Boolean, default: false },
  },
  data() {
    return {
      companiesOptions: [],
      selectedCompany: null,
      apiService: new ApiService(),
      selectedStatus: null,
      allocationStartMenuDate: false,
      allocationStartDateText: null,
      allocationStartDate: null,
      allocationEndMenuDate: null,
      allocationFinishDateText: null,
      allocationFinishDate: null,
      searchInput: null,
      allocationStatusEnums,
      allocationTabEnums,
      allocationFinalized: false,
      formatCurrency,
      formatDate,
      allocationStatusItems: [
        {
          text: "formalization",
          value: allocationStatusEnums.FORMALIZATION,
        },
        {
          text: "allocation_request",
          value: allocationStatusEnums.PLACEMENT_REQUEST,
        },
        {
          text: "allocation_analysis",
          value: allocationStatusEnums.PLACEMENT_REVIEW,
        },
        {
          text: "single_declined",
          value: allocationStatusEnums.DENIED,
        },
        {
          text: "transfer",
          value: allocationStatusEnums.TRANSFER_FUNDS,
        },
        {
          text: "completion_the_operation",
          value: allocationStatusEnums.OPERATION_SETTING,
        },
        {
          text: "round_status_canceled",
          value: allocationStatusEnums.CANCELLED,
        },
        {
          text: "released_capture",
          value: allocationStatusEnums.SENT_CAPTABLE,
        },
      ],
      page: 1,
      pageCount: 1,
      orderBy: null,
      orderByMode: OrderByModeEnum.ASC,
      orderFilterEnum: OrderFilterEnum,
      orderByModeEnum: OrderByModeEnum,
      allocations: [],
      statusText: null,
      loading: false,
    };
  },
  async created() {
    if (!this.filterByRound) {
      await this.getAllCompanies();
    }
    await this.getAllocationsByPage();
  },
  computed: {
    ...mapGetters({ isManagerUser: "get_is_manager_user" }),
  },
  methods: {
    changePage() {
      this.getAllocationsByPage();
    },
    goToAllocationDetails(id) {
      this.$router.push(`/allocations/${id}`);
    },
    headerClick(col) {
      this.orderBy = col;

      if (col === this.changeIconOrder) {
        this.changeIconOrder = null;
      } else {
        this.changeIconOrder = col;
      }

      if (this.orderByMode == 1) {
        this.orderByMode = 2;
      } else {
        this.orderByMode = 1;
      }
      this.getAllocationsByPage();
    },
    getAllCompanies() {
      this.apiService
        .getRequest("company/list")
        .then((result) => {
          this.companiesOptions = JSON.parse(result.message);
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
    },
    formatInputDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveAllocationStartDate(date) {
      this.allocationStartDateText = this.formatInputDate(
        this.allocationStartDate
      );
      this.$refs.startMenuDateRef.save(date);
    },
    saveAllocationEndDate(date) {
      this.allocationFinishDateText = this.formatInputDate(
        this.allocationFinishDate
      );
      this.$refs.endMenuDateRef.save(date);
    },
    goInvestorProfile(investorId) {
      // se id for null, ele não pode visualizar o form do investidor.
      if (!investorId) return;

      // se ele for um partner+manager e tiver acesos ao investidor, vamos mandar ele par a mesma rota do parceiro.
      // ele vai ver o mesmo form que o parceiro vê, quando está logado.
      if (this.isManagerUser) {
        this.$router.push(`/investors/info/${investorId}`);
        return;
      }

      // else
      this.$router.push(`/users/edit/${investorId}`);
    },
    formatDateOnBlur(date) {
      const [day, month, year] = date.split("/");
      let formateDate;

      if (date.length) {
        formateDate = `${year}-${month}-${day}`;
      } else {
        formateDate = null;
      }

      return formateDate;
    },
    translateAllocationsStatus(item) {
      return this.$t(item.text);
    },
    changeAllocationTab(tab) {
      if (tab.value === this.allocationTabEnums.PROGRESS) {
        this.allocationFinalized = false;
      } else {
        this.allocationFinalized = true;
      }

      this.getAllocationsByPage();
    },
    selectedAllocationStatus(status) {
      let statusText;

      switch (status) {
        case this.allocationStatusEnums.FORMALIZATION:
          statusText = "formalization";
          break;
        case this.allocationStatusEnums.PLACEMENT_REQUEST:
          statusText = "allocation_request";
          break;
        case this.allocationStatusEnums.PLACEMENT_REVIEW:
          statusText = "allocation_analysis";
          break;
        case this.allocationStatusEnums.DENIED:
          statusText = "single_declined";
          break;
        case this.allocationStatusEnums.TRANSFER_FUNDS:
          statusText = "transfer";
          break;
        case this.allocationStatusEnums.OPERATION_SETTING:
          statusText = "completion_the_operation";
          break;
        case this.allocationStatusEnums.SENT_CAPTABLE:
          statusText = "released_capture";
          break;
        case this.allocationStatusEnums.CANCELLED:
          statusText = "round_status_canceled";
          break;
        default:
          statusText = "formalization";
      }

      return statusText;
    },
    selectedAllocationStatusColor(status) {
      const documentStyle = window.getComputedStyle(document.documentElement);
      let statusColor = documentStyle.getPropertyValue("--dark");

      if (
        status === this.allocationStatusEnums.PLACEMENT_REVIEW ||
        status === this.allocationStatusEnums.TRANSFER_FUNDS ||
        status === this.allocationStatusEnums.OPERATION_SETTING
      ) {
        statusColor = "#FF9601";
      } else if (
        status === this.allocationStatusEnums.CANCELLED ||
        status === this.allocationStatusEnums.DENIED
      ) {
        statusColor = "#FF0000";
      }

      return statusColor;
    },
    async getAllocationsByPage() {
      this.loading = true;
      let currentPage = this.page;

      let params = { Page: currentPage };
      params.finalized = this.allocationFinalized;

      if (this.roundId) {
        params.roundId = this.roundId;
      }

      if (this.selectedCompany) {
        params.companyId = this.selectedCompany;
      }

      if (this.selectedStatus !== null) {
        params.status = this.selectedStatus;
      }

      if (this.allocationStartDate) {
        params.startDate = this.allocationStartDate.toString();
      }

      if (this.allocationFinishDate) {
        params.endDate = this.allocationFinishDate.toString();
      }

      if (this.searchInput) {
        params.name = this.searchInput;
      }

      if (this.orderBy != null) {
        params.orderBy = this.orderBy;
        params.order = this.orderByMode;
      }

      await this.apiService
        .postRequest(`companyinterest/list-filter/allocations`, params)
        .then((response) => {
          const totalItemsPorPage = 20;

          this.page = response.content.currentPage;
          this.pageCount = Math.ceil(
            response.content.total / totalItemsPorPage
          );
          this.allocations = response.content.items;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error(this.$t("error_occoured"));
        });
    },
  },
};
</script>

<style scoped>
.interest-table-component-container {
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
}

.interests-table-component-filters-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 8px;
  display: block;
}

.interests-table-component-filters-auto-complete {
  min-width: 210px;
}

.interest-table-component-button-filter {
  max-width: 100px;
}

.interest-table-component-filter-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.allocation-date-flex-items {
  display: flex;
  align-items: center;
  gap: 20px;
}

.allocation-date-item-space-letter {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
  display: block;
  margin-bottom: 20px;
}

/* etilos da tabela */
.interest-table-thead {
  height: 80px;
}

.interest-table-thead > th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  padding-left: 16px;
  margin-bottom: 20px;
}

.interest-table-body > td {
  padding-left: 0px !important;
}

.interest-table-body td > span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

#interest-table-body-investor-name {
  font-weight: 700;
  text-decoration-line: underline;
  color: var(--primary);
  cursor: pointer;
}

#interests-table-body-see-details {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

#interests-table-body-see-details > svg {
  color: var(--primary);
  width: 24px;
  height: 16px;
}

#interests-table-body-see-details > span {
  font-weight: 700;
  color: var(--primary);
}

.arrow-table-icon {
  width: 24px;
  height: 16px;
  cursor: pointer;
}

.table-thead-flex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.box-loading {
  display: grid;
  place-items: center;
  margin-top: 40px;
}

@media only screen and (max-width: 1140px) {
  .interests-table-component-filters-auto-complete {
    min-width: auto;
  }
}
</style>
